.adminsetting-container {
    padding: 30px;
}

.setting-grid-container {
    background-color: black;
}

.action-btn {
    margin: 20px 5px 0px 5px;    
}

.action-btn:hover {
    cursor: pointer;
}

.add-card-btn {
    position: absolute !important;
    right: 30px;
}