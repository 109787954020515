:card-img {
    --card-rotate-y: 0deg;
    --card-rotate-x: 0deg;
}

.card-img {
    height: auto;
    width: auto;
    max-width: 320px;
    margin: 1rem;
    border-radius: 20px;
    background-color: transparent;
    transition: transform .2s ease;
}

.card-img.active-card {
    transform: rotateY(var(--card-rotate-y)) rotateX(var(--card-rotate-x));
}

.container-card-menu {
    padding: 20px 20px 0 20px;
    text-align: center;
    height: calc(100vh - 70px);
    overflow-y: scroll;
    position: relative;
    top: -20px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.container-card-menu::-webkit-scrollbar {
    display: none;
}

.input-filters {
    background-color: black;
    color: #f7dd5c;
    border-color: #f7dd5c;
    border-radius: 0;
}

.input-filters:hover {
    border-color: #f7dd5c;
}

.input-filters .ant-select-selector {
    background-color: black !important;
    color: #f7dd5c !important;
    border-color: #f7dd5c !important;
    border-radius: 0 !important;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    font-size: 1rem;
    margin-top: -12px;
}

.input-filters .ant-select-arrow, .input-filters .ant-select-clear {
    color: #f7dd5c !important;
    background-color: black;
}

.input-filters .ant-select-selection-item {
    background-color: #404040;
    border: none;
}

.input-filters .ant-select-selection-item-remove {
    color: #f7dd5c !important;
}

.setting-btn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.loading {
    color: white;
    font-size: 10rem !important;
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}

.invisible {
    background-color: transparent;
    border: none;
}

.header-background {
    width: 100%;
    height: 80px;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%);
    position: relative;
    z-index: 10;
}

.menu-links {
    position: absolute;
    top: 10px;
    right: 10px;
}

.menu-links > * {
    margin: 0 5px;
}

.loading-cards {
    width: 50rem;
}

@media screen and (max-width: 400px) {
    .container-card-menu {
        padding: 0px;
    }

    .card-img {
        width: 100vw;
        margin: 10px 0px;
    }

    .loading-cards {
        width: 100vw;
    }

    .header-background .filters {
        display: none;
    }
}