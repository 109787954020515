.full-card {
    border-radius: 15px;
    height: 100vh;
    width: auto;
}

.tool-btn {
    position: absolute;
    color: white;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.815);
    padding: 15px 15px 9px 15px;
    border-radius: 100px;
    cursor: pointer;
}

.tool-back-arrow {
    left: 30px;
    top: 15px;
}

.tool-reset {
    right: 30px;
    top: 15px;
}

.tool-zoom-out {
    right: 30px;
    bottom: 15px;
}

.tool-zoom-in {
    right: 30px;
    bottom: 80px;
}

@media (max-width: 600px) {
    .full-card {
        height: 100%;
        width: 100vw;
        margin-top: 40%;
        margin-bottom: 40%;
    }
}