body {
  background-color: black !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.rotate {
  animation: App-logo-spin infinite 2s linear;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.center {
  text-align: -moz-center;
  text-align: -webkit-center;
}

.MuiDataGrid-root {
  width: 100%;
}

.MuiDataGrid-main {
  color: white;
}

.MuiDataGrid-footer, .MuiTablePagination-root, .MuiIconButton-label {
  color: white !important;
}

.Mui-selected {
  background-color: rgb(66, 66, 66) !important;
}

.MuiIconButton-root.Mui-disabled {
  color: rgb(150, 150, 150) !important;
}

.center {
  text-align: center;
}

.d-none {
  display: none;
}

.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2  {
  flex: 2;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.ai-center{
  align-items: center;
}

.m-1, .my-1, .mt-1 {
  margin-top: 1rem;
}

.m-1, .my-1, .mb-1 {
  margin-bottom: 1rem;
}

.m-1, .mx-1, .ml-1 {
  margin-left: 1rem;
}

.m-1, .mx-1, .mr-1 {
  margin-right: 1rem;
}

.m-2, .my-2, .mt-2 {
  margin-top: 2rem;
}

.m-2, .my-2, .mb-2 {
  margin-bottom: 2rem;
}

.m-2, .mx-2, .ml-2 {
  margin-left: 2rem;
}

.m-2, .mx-2, .mr-2 {
  margin-right: 2rem;
}

.p-2, .py-2, .pt-2 {
  padding-top: 2rem;
}

.p-2, .py-2, .pb-2 {
  padding-bottom: 2rem;
}

.p-2, .px-2, .pl-2 {
  padding-left: 2rem;
}

.p-2, .px-2, .pr-2 {
  padding-right: 2rem;
}

.p-1, .py-1, .pt-1 {
  padding-top: 1rem;
}

.p-1, .py-1, .pb-1 {
  padding-bottom: 1rem;
}

.p-1, .px-1, .pl-1 {
  padding-left: 1rem;
}

.p-1, .px-1, .pr-1 {
  padding-right: 1rem;
}

.right {
  float: right;
}

.clickable {
  cursor: pointer;
}

.particles-js {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  z-index: -10;
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.particles-js::-webkit-scrollbar {
  display: none;
}

.particles-js > #tsparticles {
  width: 100%;
  height: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

/* Antd Customs */

.ant-notification-notice-message, .ant-notification-notice-close {
  color: white !important;
}

.notification {
  background-color: black;
  border: solid 1px #f7dd5c;
  box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.ant-select-selection-search-input, .ant-select-selection-search {
  color: white !important;
  font-size: 2.5rem;
  position: absolute;
  min-width: 200px;
}

.ant-select-dropdown {
  background-color: black !important;
  border: 1px #f7dd5c solid;
  box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  color: white !important;
}

.ant-select-item-option {
  color: white !important;
}

.ant-select-item-option-active:hover,
.ant-select-item-option-active {
  background-color: rgb(72, 72, 72) !important;
}

.ant-select-item-option-selected {
  background-color: rgb(44, 44, 44) !important;
}

.ant-table-thead th.ant-table-column-sort::before,
.ant-table-thead th.ant-table-cell::before {
    background-color: #f7dd5c !important;
}

.ant-table-tbody > tr > td {
    border-bottom-color: #f7dd5c;
}

.ant-modal-content {
  background-color: black;
  border: solid 1px #f7dd5c;
  box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
              inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.ant-modal-content .ant-input {
  background-color: black;
  border: solid 1px #f7dd5c;
  color: white;
  box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.ant-select-selector,
.ant-select-selector:hover,
.ant-select-selector:visited,
.ant-select-selector:focus,
.ant-select-selector:active {
  background-color: black !important;
  border-color: #f7dd5c !important;
  color: white;
  box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
  -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.ant-table-tbody input, .ant-table-cell textarea {
  box-shadow: none !important;
}

.ant-input, 
.ant-input:hover,
.ant-input:active,
.ant-input:visited,
.ant-input:focus {
    background-color: black;
    color: white;
    border-color: #f7dd5c;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}


/*particles.js*/
.particles-js-canvas-el {
  position: fixed;
  z-index: -1;
  height: 100vh !important;
  width: 100vw !important;
}