.title-rolling {
    color: white;
    text-align: center;
}

.ant-form-item-label > label {
    color: white;
}

.results-rolling {
    border: solid 1px #f7dd5c;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    background-color: black;
}

.roll-button {
    background-color: #f7dd5c;
    border: solid 1px #f7dd5c
}

.roll-button:hover {
    color: #f7dd5c;
    background-color: black;
    border: solid 1px #f7dd5c;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.back-arrow {
    color: #f7dd5c;
}