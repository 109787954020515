.character-container {
    color: white;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
}

.info-container {
    background-color: black;
    display: grid;
    grid-template-columns: 1fr 1fr 400px;
    grid-template-rows: 100px 100px 100px;
    grid-template-areas: "lastname species image"
                         "firstname job image"
                         "age hp image";
    margin: 0px 40px 40px 40px;
    place-items: center start;
    padding: 30px;
    border: solid 2px #f7dd5c;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.info-container > .lastname {
    grid-area: lastname;
}

.info-container > .firstname {
    grid-area: firstname;
}

.info-container > .age {
    grid-area: age;
}

.info-container > .species {
    grid-area: species;
    display: flex;
    width: 100%;
    align-items: center;
}

.info-container > .species > .ant-select {
    flex: 1;
}

.info-container > .job {
    grid-area: job;
    display: flex;
    width: 100%;
    align-items: center;
}

.info-container > .job > .ant-select {
    flex: 1;
}

.info-container > .image {
    grid-area: image;
    justify-self: center;
    align-self: end;
    position: relative;
}

.info-container > .hp {
    grid-area: hp;
}

.info-container > .hp > input.dead-hp {
    color: red;
}

.info-container > .hp > input.critical-hp {
    color: orange;
}

.info-container > .hp > input.over-hp {
    color: green;
}

.info-container > .image .character-add-image {
    background-color: transparent;
    border: none;
}

.info-container > .image .character-add-image:hover {
    cursor: pointer;
}

.info-container > .image .character-remove-image {
    background: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    font-weight: 700;
    color: red;
    z-index: 10;
}

.info-container > .image .character-remove-image:hover {
    cursor: pointer;
}

.info-container > .image .preview-image {
    border: 1px solid #f7dd5c;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    height: 300px;
    width: 300px;
    position: relative;
}

.info-container > .image .button-upload {
    height: calc(300px - 2px);
    width: calc(300px - 2px);
    color: white !important;
    padding: 0;
}

.info-container > .image .button-upload .modal-image {
    position: relative;
}

.info-container > .image .preview-image .remove-image {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
}

.info-container > .image .preview-image .remove-image.hidden {
    display: none;
}

.info-container > .image .preview-image .background-image {
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
    width: 100%;
    height: 100%;
    z-index: 50;
    cursor: pointer;
}

.info-container > div > input {
    background-color: transparent;
    width: 200px;
    height: 70px;
    border: none;
    font-size: 2.5rem;
}

.info-container > div > span {
    color: rgb(147, 147, 147);
    font-size: 2.5rem;
}

.info-container > div > .ant-select {
    height: 50px;
    border: none;
    font-size: 2.5rem;
    color: white;
}

.info-container > div > .ant-select > .ant-select-selector {
    height: 45px;
    align-items: center;
}

.info-container > div > .ant-select > * {
    box-shadow: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    border: none !important;
}

.icon-section{
    font-size: 1.5rem;
    color: #f7dd5c;
}

.row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 40px;
}

.column-container {
    display: flex;
    width: calc(50% - 20px);
    flex-direction: column;

}

.column-container > div {
    margin-bottom: 40px;
}

.stats-container,
.equip-container,
.fight-container,
.lore-container,
.note-container,
.competencies-container {
    background-color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    border: solid 2px #f7dd5c;
    padding: 30px;
    height: fit-content;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.title {
    font-size: 2rem;
    margin-bottom: 15px;
}

.grid {
    display: grid;
    font-size: 1.5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px;
    place-items: center;
}

.grid > div {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
}

.grid > div > input {
    width: 100px;
    background-color: transparent;
    margin-left: 10px;
    border: none;
}

.grid .graph-container {
    place-self: end;
    height: 30px;
}

.grid > .graph-container > .graph-background {
    width: 100%;
}

.grid > .graph-container > .graph-background > .graph {
    height: 30px;
    background-color: #f7dd5c;
    margin-left: auto;
    margin-right: 0;
}

.grid > div > .name-cat {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.puissname > .ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    color: white !important;
    font-size: 1.5rem;
    padding: 0 !important;
    min-width: 80px !important;
}

.puissname > .ant-select-selector > span,
.puissname > .ant-select-selector > span > input {
    min-width: 80px !important;
}

.puissname .ant-select-selection-item {
    padding-right: 10px !important;
}

.puissname .ant-select-selector {
    box-shadow: none !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

.ant-select-disabled > .ant-select-selector,
.ant-select-disabled input {
    cursor: default !important;
}

.list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(16, 50px);
    grid-template-areas: "race job"
                         "lvl hp"
                         ". ."
                         "att att"
                         "def def"
                         "vit vit"
                         "agi agi"
                         "puiss puiss"
                         "stren stren"
                         ". ."
                         "cac cac"
                         "dist dist"
                         "mag mag"
                         "def_phy def_phy"
                         "def_mag def_mag"
                         "dodge dodge";
    place-items: center start;
    font-size: 1.5rem;
}

.list > .species {
    grid-area: race;
}

.list > .job {
    grid-area: job;
}

.list > .level {
    grid-area: lvl;
}

.list > .hp {
    grid-area: hp;
}

.list > .att {
    grid-area: att;
}

.list > .def {
    grid-area: def;
}

.list > .vit {
    grid-area: vit;
}

.list > .agi {
    grid-area: agi;
}

.list > .puiss {
    grid-area: puiss;
}

.list > .stren {
    grid-area: stren;
}

.list > .cac {
    grid-area: cac;
}

.list > .dist {
    grid-area: dist;
}

.list > .mag {
    grid-area: mag;
}

.list > .def_phy {
    grid-area: def_phy;
}

.list > .def_mag {
    grid-area: def_mag;
}

.list > .dodge {
    grid-area: dodge;
}

.list input {
    width: 70px;
    background-color: transparent;
    border: none;
    margin-left: 5px;
}

.lore-container > textarea, .note-container > textarea {
    background-color: black;
    color: white;
    resize: none;
}

.add-competency {
    font-size: 1.2rem;
    margin-left: 10px;
}

.equipment {
    font-size: 1.5rem;
}

.tooltip-equipment {
    background-color: black;
}

.ant-popover-arrow-content::before {
    color: #f7dd5c;
}

.ant-popover-inner {
    border: solid 1px #f7dd5c;
    border-radius: 5px;
}

.add-equipment {
    font-size: 1.2rem;
    margin-left: 10px;
}

.select-equipment > .ant-select-selector {
    background-color: black !important;
    border: solid 1px #f7dd5c !important;
}

.select-equipment .ant-select-arrow {
    color: #f7dd5c;
}

.select-equipment {
    width: 150px;
}

.select-equipment {
    background-color: transparent;
    border: none;
}

.equiped-item {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas: "name carac actions";
}

.equiped-item-name {
    grid-area: name;
    border: solid 1px;
    padding: 5px 10px;
    align-items: center;
    display: flex;
}

.equiped-item-carac {
    grid-area: carac;
    border: solid 1px;
    padding: 5px 10px;
    align-items: center;
    display: flex;
}

.equiped-item-actions {
    grid-area: actions;
    display: flex;
    justify-content: center;
    border: solid 1px;
    padding: 5px 10px;
    align-items: center;
}

.ant-select-item-empty {
    color: white;
    display: flex;
}

.skill-container {
    display: flex;
    flex-direction: column;
}

.skill-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.skill-edited-name-text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.skill-edited-name-text > input  {
    margin-right: 10px;
    color: white;
    background-color: black;
    border-color: #f7dd5c !important;
}

.skill-edited-desc-text > textarea {
    color: white;
    background-color: black;
    border-color: #f7dd5c !important;
}

.skill-name-text {
    font-size: 1.2rem;
    text-decoration: underline;
}

.skill-icon {
    font-size: 1.3rem;
    opacity: 0;
    margin-left: 10px;
}

.skill-icon:hover,
.skill-edited-desc-text .skill-icon,
.skill-edited-name-text .skill-icon {
    opacity: 1;
}

.skill-desc-text > textarea {
    color: white;
    background-color: black;
    border: none !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    resize: none;
    width: 100%;
}

.loading-character {
    width: 50rem;
}

@media screen and (min-width: 901px) and (max-width: 1024px) {
    .info-container > div > span, .info-container > div > input, .info-container > div > .ant-select {
        font-size: 1.5rem;
    }
    
    .grid {
        font-size: 1rem;
        grid-template-columns: 100%;
        grid-template-rows: repeat(22, 30px);
    }

    .grid > div > .name-cat {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .puissname > .ant-select-selector {
        font-size: 1rem;
    }

    .title {
        font-size: 1.5rem;
    }

    .list {
        grid-template-rows: repeat(12, 40px);
        grid-template-areas: "race job"
                             "lvl lvl"
                             ". ."
                             "att att"
                             "def def"
                             "vit vit"
                             "agi agi"
                             "puiss puiss"
                             "stren stren"
                             ". ."
                             "deg armor"
                             "parad dodge";
        font-size: 1rem;
    }

    .skill-icon {
        opacity: 1;
    }
}

@media screen and (max-width: 900px) {
    .loading-character {
        width: 100vw;
    }

    .ant-modal-mask, .ant-modal-wrap {
        width: 100vw;
    }

    .info-container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-template-rows: 80vw 20px 1fr 1fr 1fr;
        grid-template-areas: "image image"
                             ". ."
                             "lastname species"
                             "firstname job"
                             "age hp";
        margin: 10px;
        place-items: center start;
        padding: 10px;
    }

    .info-container > div > input {
        width: 95%;
    }

    .info-container > div > span, .info-container > div > input, .info-container > div > .ant-select {
        font-size: 1.5rem;
    }

    .info-container > .image {
        align-self: unset;
    }

    .info-container > div {
        display: flex;
        flex-direction: column;
    }
    
    .grid {
        font-size: 1rem;
        grid-template-columns: 100%;
        grid-template-rows: repeat(22, 30px);
    }

    .grid > div > .name-cat {
        width: 50%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .grid > div > input {
        width: 50px;
    }

    .puissname {
        width: 50%;
    }

    .puissname > .ant-select-selector {
        font-size: 1rem;
    }

    .grid > .graph-container > .graph-background > .graph {
        height: 20px;
    }

    .title {
        font-size: 1.2rem;
    }

    .list {
        grid-template-columns: 100%;
        grid-template-rows: repeat(18, 40px);
        grid-template-areas: "race" 
                             "job"
                             "lvl"
                             "hp"
                             "."
                             "att"
                             "def"
                             "vit"
                             "agi"
                             "puiss"
                             "stren"
                             "."
                             "cac"
                             "dist"
                             "mag"
                             "def_phy"
                             "def_mag"
                             "dodge";
        font-size: 1rem;
    }

    .list input {
        width: 80px;
    }

    .equipment {
        font-size: 1rem;
    }

    .row-container {
        flex-direction: column;
        margin: 10px;
    }

    .column-container {
        width: 100%;
    }

    .ant-select-selection-search-input, .ant-select-selection-search {
        min-width: auto;
    }

    .skill-icon {
        opacity: 1;
    }
}