.search-container {
    padding : 20px 50px 0 50px;
    display: flex;
}

.search-input {
    width: 250px;
    background-color: transparent;
    border: solid 1px #f7dd5c !important;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.search-input input {
    background-color: transparent;
    color: white;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
}

.search-input .ant-input-clear-icon {
    color: white;
}

.margin-table {
    margin-top: 50px;
    padding: 0 50px;
}

.margin-table textarea {
    resize: none;
    border: none;
    width: 100%;
}

.icon-header {
    margin: auto;
    display: block !important;
}

.ant-form-item-label > label {
    color: black;
}

.inventory-table .ant-table {
    border: solid 2px #f7dd5c;
    box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -webkit-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(247, 221, 92, 1), 
                inset 0px 0px 16px -7px rgba(247, 221, 92, 1);
}

.ant-table-thead th.ant-table-column-sort,
.ant-table-thead th.ant-table-cell,
td.ant-table-column-sort,
td.ant-table-cell,
.ant-table-cell-row-hover {
    color: white;
    border-bottom-color: #f7dd5c;
    background: black !important;
}

.inventory-table .ant-select-selector {
    background: black !important;
    color: white;
    border: solid 1px #f7dd5c !important;
}

.inventory-table .ant-pagination-item-link,
.inventory-table .ant-pagination-item-active {
    background: black;
    color: white;
    border: solid 1px #f7dd5c !important;
}

.inventory-table .ant-pagination-item-active > a {
    color: #f7dd5c;
}

.inventory-table .ant-table .ant-table-cell .ant-input {
    color: white;
}

.inventory-table .ant-select-arrow {
    color: white;
    border-bottom-color: #f7dd5c;
    background: black !important;
}

.modal-new-item .ant-modal-body {
    padding-top: 50px;
}

.add-to-inventory .ant-select-arrow {
    color: #f7dd5c;
}

.ant-modal-footer {
    border-top: solid 1px #f7dd5c;
}

.ant-modal-footer .ant-btn-default {
    background-color: black;
    color: #f7dd5c;
    border-color: #f7dd5c !important;
}

.ant-modal-footer .ant-btn-primary {
    background-color: #f7dd5c;
    color: black;
    border-color: #f7dd5c;
}

.add-to-inventory {
    background-color: black;
    color: #f7dd5c;
}

.ant-form-item-label > label {
    color: #f7dd5c;
}

.ant-pagination-item {
    background-color: #f7dd5c;
    border-color: #f7dd5c;
}

.ant-pagination-item:hover,
.ant-pagination-item:hover > a {
    background-color: black;
    border-color: #f7dd5c;
    color: #f7dd5c;
}

.ant-pagination-jump-next, .ant-pagination-jump-prev,
.ant-pagination-jump-next .ant-pagination-item-link,
.ant-pagination-jump-prev .ant-pagination-item-link {
    display: flex;
    border-color: #f7dd5c;
    background: #f7dd5c;
    text-align: center;
    color: black;
}

.ant-pagination-jump-prev:hover, .ant-pagination-jump-next:hover,
.ant-pagination-jump-next .ant-pagination-item-link:hover,
.ant-pagination-jump-prev .ant-pagination-item-link:hover {
    border-color: #f7dd5c;
    background: black;
    color: #f7dd5c;
}

.ant-pagination-item-container {
    width: 32px;
}

.ant-pagination-item-container svg {
    color: #f7dd5c;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    display: block;
    border-color: #f7dd5c;
    background: #f7dd5c;
    text-align: center;
    color: black;
}

.inventory-table .ant-select-selection-search,
.inventory-table .ant-select-selection-search-input {
    width: 0;
    min-width: 0;
}

@media screen and (max-width: 900px) {
    .margin-table {
        padding: 10px;
        margin: 0px;
    }
}