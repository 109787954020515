.admin-modale-container {
    position: absolute;
    height: 100vh;
    width: 600px;
    top: 0;
    left:-1000px;
    background-color: rgb(0, 0, 0);
    box-shadow: 20px 20px 60px #313131;
    transition: all 0.3s ease;
}

.admin-modale-container.show {
    transform: translateX(1000px);
}

.modale-title {
    color: white;
    text-align: center;
    font-size: 1.75rem;
    text-decoration: underline;
}

.margin-modale {
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.close-modale-btn {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-modale-btn:hover, .add-tag:hover, .delete-tag:hover,
.input-card, .input-big-card{
    cursor: pointer;
}

.header-modale {
    height: 50px;
}

.body-modale {
    color: white;
    flex: 1;
    max-height: calc(99vh - 100px);
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.body-modale > .loading-cards {
    width: 100%;
}

.field {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.footer-modale {
    text-align: center;
    height: 50px;
}

.label-modale {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.input-textarea-modale {
    flex: 1;
    height: 100px;
    resize: none;
    border: solid 1px rgb(131, 131, 131);
    background-color: transparent;
    color: white;
    font-size: 1.25rem;
}

.input-modale {
    flex: 1;
    height: 2rem;
    border:  none;
    border-bottom: solid 1px rgb(131, 131, 131);
    background-color: transparent;
    color: white;
    font-size: 1.25rem;
}

.input-modale:focus {
    border-bottom: solid 1px white;
}

.select-modale {
    flex: 1;
    height: 49px;
    border:  none;
    background-color: transparent;
    color: white;
    font-size: 1.25rem;
}

.input-tag {
    margin-left: 10px;
    height: 1.75rem;
    color: white;
    background-color: transparent;
    border:  none;
    font-size: 1.5rem;
    border-bottom: solid 1px rgb(131, 131, 131);
}

.add-tag {
    margin-left: 20px;
}

.delete-tag {
    margin-left: 10px;
}

.tag-container {
    margin: 10px;
    display: flex;
    align-items: center;
}

.input-card {
    margin: 10px 0 20px 0;
    height: 200px;
    width: 141.3px;
    background-color: rgb(0, 0, 0);
    border: solid 1px white;
}

.input-image {
   height: 100%;
   width: 100%;
}

.button-add-image:hover {
    cursor: pointer;
}

.delete-image-icon {
    position: absolute;
    top: 14px;
    right: 10px;
}

.delete-image-icon:hover {
    cursor: pointer;
}

.input-big-card {
    margin: 10px auto 20px auto;
    height: 300px;
    width: 533.4px;
    background-color: rgb(0, 0, 0);
    border: solid 1px white;
}

.submit-btn {
    height: 30px;
    margin-top: 10px;
    padding: 5px 10px;
}