.login-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    overflow-y: hidden;
}

.background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: black;
    z-index: -100;
}

.div-centered {
    align-self: center;
    padding: 30px 15px;
    background-color: #6b6b6ba3;
    border-radius: 5px;
}

.hide-error {
    opacity: 0;
    transition: all 0.3s;
    position: absolute;
    text-align: center;
    width: 260px;
}

.error {
    color: red;
    opacity: 1;
    text-align: center;
    transition: all 0.3s;
    position: absolute;
    width: 260px;
}